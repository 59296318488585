<script setup>
const { availableLocales, locale } = useI18n()
const LANGUAGE_DISPLAY_VALUES = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  de: 'Deutsch',
}

const isOpen = ref(false)
function toggleOpen() {
  isOpen.value = !isOpen.value
}

watch(locale, (value) => {
  isOpen.value = false
})
</script>

<template>
  <div class="language-selector" :class="{ 'language-selector--open': isOpen }">
    <div v-if="isOpen" class="language-selector__list">
      <div class="language-selector__list-inner">
        <NuxtLink
          v-for="loc in availableLocales"
          :key="loc"
          :to="switchLocalePath(loc)"
          class="language-selector__option"
          :class="{ 'language-selector__option--active': loc === locale }"
        >
          <img
            class="language-selector__flag"
            :src="`/assets/images/language-selector/flag-${loc}.png`"
            alt=""
          />
          <span class="language-selector__title">{{
            LANGUAGE_DISPLAY_VALUES[loc]
          }}</span>
        </NuxtLink>
      </div>
    </div>
    <button class="language-selector__display" @click="toggleOpen">
      <img
        class="language-selector__display-flag"
        :src="`/assets/images/language-selector/flag-${locale}.png`"
        alt=""
      />
      <span class="language-selector__display-text">{{ locale }}</span>
      <Icon class="language-selector__display-icon" name="chevron-down" />
    </button>
  </div>
</template>

<style lang="scss">
@import '~/styles/vendors/sass-mq/mq';
@import '~/styles/utils/variables';

.language-selector {
  position: relative;

  &__display {
    display: flex;
    align-items: center;
    // margin-left: auto;
    font-weight: $font-weight-4G;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.27em;
    text-transform: uppercase;
    color: $brand-white;

    @include mq($from: 'l') using ($from) {
      margin-left: auto;
    }

    &-text {
      margin: 0 6px 0 8px;
    }
    &-flag {
      width: 20px;
      border: 1px solid $brand-white;
      border-radius: 50%;
    }
    &-icon {
      width: 8px;
    }
  }

  &__flag {
    width: 20px;
    border: 1px solid rgba($brand-white, 0.3);
    border-radius: 50%;
    margin-right: 8px;
  }

  &__list {
    position: absolute;
    bottom: calc(100% + 21px);
    right: 0;
    background: #130725;
    border-radius: 10px;
    padding: 12px;

    &-inner {
      max-height: 137px;
      overflow: auto;
    }
  }

  &__option {
    font-weight: $font-weight-4G;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.27em;
    text-transform: uppercase;
    color: $brand-white;
    display: flex;
    align-items: center;
    padding: 16px;
    width: 216px;
    &--active {
      background: $brand-magenta;
      border-radius: 10px;
    }
  }

  &--open {
  }
}
</style>
