<script setup>
import { useModalStore } from '~/store/modal'
import { useMenuStore } from '~/store/menu'

const modalStore = useModalStore()
const modalIsOpen = computed(() => modalStore.modal)
const menuStore = useMenuStore()
const menuIsOpen = computed(() => menuStore.isOpen)
const route = useRoute()

const props = defineProps({
  isLight: {
    type: Boolean,
    default: false,
  },
})

const isFlyWithUsPage = computed(() => route.name.startsWith('sign-up'))
const showLogo = computed(
  () => !modalIsOpen.value || route.name.startsWith('gallery')
)
const showGradient = computed(
  () => !(modalIsOpen.value && route.name.startsWith('gallery'))
)
const isNxtGen = computed(() => route.path.startsWith('/nxtgen'))

const showToggle = computed(
  () =>
    !props.isLight &&
    !modalIsOpen.value &&
    !menuIsOpen.value &&
    !isFlyWithUsPage.value &&
    !isNxtGen.value &&
    !['/fpo-test-3', '/NXTGEN-Intro'].includes(route.path)
)

function closeMenu() {
  menuStore.isOpen = false
}
</script>

<template>
  <header
    class="global-header container"
    :class="{
      'global-header--light': isLight,
      'global-header--gradient': showGradient,
    }"
  >
    <NuxtLink
      class="gtm-vgms-header-vg-logo-welcome-to-vg"
      v-if="showLogo"
      :to="localePath('/')"
      @click="closeMenu"
    >
      <HeaderLogo />
    </NuxtLink>
    <div class="global-header__right" v-if="route.path != '/submission-recieved' && route.path != '/survey-submission-received' && route.path != '/virgin-galactic-sign-up-page-for-sunrise' && route.path != '/virgin-galactic-example-page-for-sunrise' && route.path != '/future-astronaut-events'">
      <Transition>
        <FlyWithUsToggle
          v-if="showToggle"
          class="global-header__fly-with-us gtm-vgms-header-application-fly-with-us"
        >
          {{ $t('globalHeader.flyWithUsCTA') }}
        </FlyWithUsToggle>
      </Transition>

      <MenuToggle />
    </div>
  </header>
</template>

<style lang="scss">
// Vendors
@import 'styles/vendors/sass-mq/mq';

// Utils
@import 'styles/utils/variables';

.global-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  padding-top: 20px;
  height: 66px;
  z-index: map-get($z-layers, 'header');
  pointer-events: none;

  @include mq($from: 'm') using ($from) {
    padding-top: 56px;
    height: 102px;
  }

  &::before {
    content: '';
    background: linear-gradient(
      180deg,
      rgba(12, 1, 26, 0.5) 0%,
      rgba(13, 4, 27, 0.2) 40%,
      rgba(13, 3, 27, 0.03) 76%,
      rgba(15, 8, 29, 0) 100%
    );
    display: block;
    width: 100%;
    height: 170px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1.5s ease;

    @include mq($from: 'l') using ($from) {
      height: 155px;
      max-height: 100vh;

      background-image: linear-gradient(
        to top,
        hsla(260.77, 72.22%, 7.06%, 0.41) 0%,
        hsla(260.77, 72.22%, 7.06%, 0.405) 8.1%,
        hsla(260.77, 72.22%, 7.06%, 0.39) 15.5%,
        hsla(260.77, 72.22%, 7.06%, 0.367) 22.5%,
        hsla(260.77, 72.22%, 7.06%, 0.338) 29%,
        hsla(260.77, 72.22%, 7.06%, 0.304) 35.3%,
        hsla(260.77, 72.22%, 7.06%, 0.266) 41.2%,
        hsla(260.77, 72.22%, 7.06%, 0.225) 47.1%,
        hsla(260.77, 72.22%, 7.06%, 0.185) 52.9%,
        hsla(260.77, 72.22%, 7.06%, 0.144) 58.8%,
        hsla(260.77, 72.22%, 7.06%, 0.106) 64.7%,
        hsla(260.77, 72.22%, 7.06%, 0.072) 71%,
        hsla(260.77, 72.22%, 7.06%, 0.043) 77.5%,
        hsla(260.77, 72.22%, 7.06%, 0.02) 84.5%,
        hsla(260.77, 72.22%, 7.06%, 0.005) 91.9%,
        hsla(260.77, 72.22%, 7.06%, 0) 100%
      );
      mix-blend-mode: normal;
      transform: rotate(180deg);
    }
  }

  &--gradient {
    &::before {
      opacity: 1;
    }
  }

  & > * {
    position: relative;
    pointer-events: auto;
  }

  .menu-toggle {
    margin-left: 11px;

    @include mq($from: 'm') using ($from) {
      margin-left: 16px;
    }
  }

  &__fly-with-us {
    &.v-enter-active,
    &.v-leave-active {
      transition: opacity 0.5s ease 0.25s;
    }
    &.v-enter-from,
    &.v-leave-to {
      opacity: 0;
    }
  }

  &__right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &--light {
    position: absolute;
  }
}
</style>
